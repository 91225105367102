import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SplashPage from "./SplashPage";
import { Helmet } from "react-helmet-async";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import "../styles/blogDetail.css";
import ReactDOM from "react-dom";


const renderers = {
  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || "");
    return !inline && match ? (
      <SyntaxHighlighter
        style={vscDarkPlus}
        language={match[1]}
        PreTag="div"
        {...props}
      >
        {String(children).replace(/\n$/, "")}
      </SyntaxHighlighter>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
  
  strong({ children }) {
    return (
      <strong style={{ color: "#ffffff", fontWeight: 700 }}>{children}</strong>
    );
  },
  
  ul({ children }) {
    return <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>{children}</ul>;
  },
  li({ children }) {
    return <li style={{ margin: "0.5rem 0" }}>{children}</li>;
  },
};


const blogPosts = [
  {
    id: "arclbroth",
    title: "LA CTF 2025 - Arclbroth (Web Exploitation)",
    images: [
      "/assets/images/arclbroth-1.webp",
      "/assets/images/arclbroth-2.webp",
      "/assets/images/arclbroth-3.webp",
      "/assets/images/arclbroth-4.webp",
      "/assets/images/arclbroth-5.webp",
    ],
    content: [
      {
        image: "/assets/images/arclbroth-1.webp",
      },
      {
        heading: "Overview",
        text: `Arclbroth is a web-game-based challenge where your objective is to brew a flag using "arcs" as your currency. Regular users start with just 10 arcs which is too few to meet the 50-arc threshold required to brew the flag while the admin account is automatically granted 100 arcs. Normally, the username "admin" is reserved and protected against duplicate registrations. However, by exploiting a null byte injection vulnerability during registration, I managed to bypass this restriction and register as the admin then voila I got the flag :)`,
      },
      {
        heading: "Vulnerability",
        text: `The challenge contained a **Null Byte Injection** vulnerability in the registration system. By injecting a null byte into the username, I was able to trick the backend into treating my username as "admin".`,
      },
      {
        heading: "Intercepting the Registration Request",
        text: `Using Burp Suite, I intercepted the registration request and attempted to register with the following credentials:

**Username:** admin\\u0000hax  
**Password:** password

The website automatically added an extra escape character as you can see the picture below.`,
      },
      {
        image: "/assets/images/arclbroth-2.webp",
      },
      {
        heading: "Correcting the Payload",
        text: `Before forwarding the request, I modified the payload by removing the extra escape character.`,
      },
      {
        image: "/assets/images/arclbroth-3.webp",
      },
      {
        heading: "Logging in as Admin",
        text: `After registering with the manipulated credentials, the server processed my username as **admin**. This granted me access to the admin account with 100 arcs.`,
      },
      {
        image: "/assets/images/arclbroth-4.webp",
      },
      {
        heading: "Brewing the Flag",
        text: `Finally, I clicked the **brew** button. Since I had 100 arcs, the system deducted 50 arcs and returned the flag: **lactf{bulri3v3_it_0r_n0t_s3cur3_sqlit3_w4s_n0t_s3cur3}**`,
      },
      {
        image: "/assets/images/arclbroth-5.webp",
      },
    ],
  },{
    id: "sillyCloud",
    title: "TUCTF 2024 - Silly Cloud (Web Exploitation)",
    images: [
      "/assets/images/sillyCloud-1.webp",
      "/assets/images/sillyCloud-2.webp",
      "/assets/images/sillyCloud-3.webp",
      "/assets/images/sillyCloud-4.webp",
      "/assets/images/sillyCloud-5.webp",
      "/assets/images/sillyCloud-6.webp",
      "/assets/images/sillyCloud-7.webp",
      "/assets/images/sillyCloud-8.webp",
    ],
    content: [
      {
        image: "/assets/images/sillyCloud-1.webp",
      },
      {
        heading: "Overview",
        text: `Silly Cloud is a cloud developer platform challenge from TUCTF 2024. Beneath the buzzwords was a **Local File Inclusion (LFI)** vulnerability. By leveraging LFI, I accessed Kubernetes tokens and certificates, then got the flag :)`,
      },
      {
        heading: "Vulnerability",
        text: `The application was vulnerable to LFI, which meant that I could traverse directories and read files that were not meant to be exposed.`,
      },
      {
        heading: "Using LFI to Get the Token",
        text: `I navigated to **/var/run/secrets/kubernetes.io/serviceaccount/token** using LFI, allowing me to read the service account token. This token was key to authenticating against the cluster.`,
      },
      {
        image: "/assets/images/sillyCloud-2.webp",
      },
      {
        heading: "Retrieving the CA Cert",
        text: `Next, I located the CA certificate at **/var/run/secrets/kubernetes.io/serviceaccount/ca.crt**. This, along with the token, would let me securely connect to the cluster.`,
      },
      {
        image: "/assets/images/sillyCloud-3.webp",
      },
      {
        heading: "Checking Environment Variables",
        text: `By examining **/proc/self/environ**, I discovered the secret namespace and cluster address. These environment variables revealed how to target the correct namespace inside the cluster.`,
      },
      {
        image: "/assets/images/sillyCloud-4.webp",
      },
      {
        heading: "Configuring kubectl",
        text: `Using the token, CA cert, namespace and cluster address, I configured my local **kubectl**.`,
      },
      {
        image: "/assets/images/sillyCloud-5.webp",
      },
      {
        heading: "Checking Our Permissions",
        text: `I ran **kubectl auth can-i** to see what this service account could do. It turned out I had permission to get top-secret-flag.`,
      },
      {
        image: "/assets/images/sillyCloud-6.webp",
      },
      {
        heading: "Accessing the Secret File",
        text: `Inside that namespace, I found a secret object that contained the flag. The data was stored in base64 form, so I needed to decode it to retrieve the actual flag.`,
      },
      {
        image: "/assets/images/sillyCloud-7.webp",
      },
      {
        heading: "Decoding the Flag",
        text: `Once I decoded the base64-encoded secret, I obtained the flag: **TUCTF{3ven_m04r_51lly_d3f4ul75}**`,
      },
      {
        image: "/assets/images/sillyCloud-8.webp",
      },
    ],
  }, {
    id: "mavsFan",
    title: "LA CTF 2025 - MavsFan (Web Exploitation)",
    images: [
      "/assets/images/mavs-1.webp",
      "/assets/images/mavs-2.webp",
      "/assets/images/mavs-3.webp",
      "/assets/images/mavs-4.webp",
      "/assets/images/mavs-5.webp",
      "/assets/images/mavs-6.webp",
      "/assets/images/mavs-7.webp",
    ],
    content: [
      {
        image: "/assets/images/mavs-1.webp",
      },
      {
        heading: "Overview",
        text: `We are given a site and an admin bot. The site has a vulnerable textarea that lets you store an XSS payload, which then ends up as a post with its own unique URL. I fed that URL to the admin bot, so when it visited the page, the payload worked and fetched the flag from the bot and sent it to my webhook. And it is hilarious that I couldn't help but laugh at the author a die hard Mavs fan especially after hearing that Luka got traded to the Lakers.`
      },
      {
        heading: "Vulnerability",
        text: `The site's massive textarea doesn't sanitize user input, making it a perfect setup for a **stored XSS attack**. You can drop in your malicious script, and it stays there as part of the post to execute every time someone checks it out.`
      },
      {
        heading: "Exploring the Website",
        text: `While checking out the site, I noticed a textarea in the post editor that made it clear an XSS payload could be stored. Since every post gets a unique URL, feeding that URL to the admin bot ensures our payload works when it visits, therefore giving us the flag.`
      },
      {
        image: "/assets/images/mavs-2.webp",
      },
      {
        image: "/assets/images/mavs-3.webp",
      },
      {
        heading: "Crafting the Payload",
        text: `I created an SVG payload that when triggered it sends a fetch request to the **/admin** endpoint and forwards the result to my webhook. How do I know my XSS payload worked? see the gap inside the post.`
      },
      {
        image: "/assets/images/mavs-4.webp",
      },
      {
        image: "/assets/images/mavs-5.webp",
      },
      {
        heading: "Feeding the Admin Bot",
        text: `Next, I fed the admin bot the URL of my post with the payload. Bro doesn't know what's gonna happen 😈.`
      },
      {
        image: "/assets/images/mavs-6.webp",
      },
      {
        heading: "Exfiltrated Flag",
        text: `Boom! The payload worked and exfiltrated the flag, leaving the admin bot clueless 😆. The flag is: **lactf{m4yb3_w3_sh0u1d_tr4d3_1uk4_f0r_4d}**.`
      },
      {
        image: "/assets/images/mavs-7.webp",
      },
    ],
  }
];


function parseTitle(title) {
  
  const [eventPart, rest] = title.split(" - ");
  if (!rest) return [title, "", ""];
  const projectPart = rest.split(" (")[0];
  const challengePart = rest.split(" (")[1]?.replace(")", "") || "";
  return [eventPart, projectPart, challengePart];
}

function BlogDetailPage() {
  const { blogId } = useParams();
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [titleParts, setTitleParts] = useState([]);
  const scrollYRef = useRef(0);

  useEffect(() => {
    const selectedBlog = blogPosts.find((b) => b.id === blogId);
    if (!selectedBlog) {
      navigate("/blogs");
      return;
    }
    setBlogData(selectedBlog);
    setTitleParts(parseTitle(selectedBlog.title));

    const preloadImages = async () => {
      await Promise.all(
        selectedBlog.content
          .map((section) => section.image)
          .filter(Boolean)
          .map(
            (src) =>
              new Promise((resolve) => {
                const img = new Image();
                img.onload = resolve;
                img.onerror = resolve;
                img.src = src;
              })
          )
      );
      setLoading(false);
    };

    preloadImages();
  }, [blogId, navigate]);
  useEffect(() => {
    if (selectedImage) {
      // Save the current scroll position
      scrollYRef.current = window.scrollY;
      // Freeze the background by applying fixed positioning
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollYRef.current}px`;
      document.body.style.left = "0";
      document.body.style.right = "0";
    } else {
      // Remove fixed positioning and restore scroll position
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.left = "";
      document.body.style.right = "";
      // Restore scroll position
      window.scrollTo(0, scrollYRef.current);
    }

    // Cleanup in case the component unmounts
    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.left = "";
      document.body.style.right = "";
    };
  }, [selectedImage]);
  if (loading) {
    return <SplashPage />;
  }

  return (
    <div className="blogDetail-container">
      <Helmet>
        <title>Ahmed Al-Naamani | {blogData.title}</title>
        <meta name="description" content={`Read the detailed write-up on ${blogData.title}.`} />
        <meta property="og:title" content={`Ahmed Al-Naamani | ${blogData.title}`} />
        <meta property="og:description" content={`Detailed write-up on ${blogData.title}.`} />
        <meta property="og:image" content="https://ahmedalnaamani.dev/assets/images/blogs.webp" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={`https://ahmedalnaamani.dev/blogs/${blogData.id}`} />
        <meta property="og:type" content="article" />
        <link rel="canonical" href={`https://ahmedalnaamani.dev/blogs/${blogData.id}`} />
      </Helmet>
      <button
        className="blogDetail-backButton"
        onClick={() => navigate("/blogs")}
      >
        ← Back to Blogs
      </button>

      <div className="blogDetail-title-wrapper">
        <h1 className="blogDetail-title-event">{titleParts[0]}</h1>
        <h2 className="blogDetail-title-project">{titleParts[1]}</h2>
        <h3 className="blogDetail-title-challenge">{titleParts[2]}</h3>
      </div>

      {blogData.content.map((section, index) => (
        <div key={index} className="blogDetail-section fadeIn">
          {section.image && (
            <div className="image-container">
              <img
                src={section.image}
                alt={`Step ${index + 1}`}
                className="blogDetail-image"
                onClick={() => setSelectedImage(section.image)}
              />
            </div>
          )}
          {section.heading && (
            <h2 className="blogDetail-heading">{section.heading}</h2>
          )}
          {section.text && (
            <ReactMarkdown components={renderers} className="blogDetail-text">
              {section.text}
            </ReactMarkdown>
          )}
        </div>
      ))}

    {selectedImage &&
      ReactDOM.createPortal(
        <div className="lightbox" onClick={() => setSelectedImage(null)}>
          <img
            src={selectedImage}
            alt="Enlarged View"
            className="lightbox-image"
            onClick={(e) => e.stopPropagation()} 
          />
        </div>,
        document.body
      )}
    </div>
  );
}

export default BlogDetailPage;