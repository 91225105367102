import React from "react";
import Slider from "react-infinite-logo-slider";
import "../styles/aboutPage.css";
import SquattingAvatar from "../components/SquatingAvatar";
import { Helmet } from "react-helmet-async";
function AboutPage(props) {
  return (
    <div className="page-transition">
      <Helmet>
        <title>Ahmed Al-Naamani | About</title>
        <meta
          name="description"
          content="Learn about Ahmed Al-Naamani, a Full Stack Developer and Software Engineer from Muscat, Oman. Discover his journey, skills, and passions."
        />
        <meta property="og:title" content="Ahmed Al-Naamani | About" />
        <meta
          property="og:description"
          content="Get to know Ahmed Al-Naamani—his background, and interests."
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image" content="https://ahmedalnaamani.dev/assets/images/blogs.webp" />
        <meta property="og:url" content="https://ahmedalnaamani.dev/about" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://ahmedalnaamani.dev/about" />
      </Helmet>
      <div className="about-cont">
        <div className="aboutIntro">
          <div className="meSquatting">
            <SquattingAvatar />
          </div>
          <div className="aboutText">
            <h1 className="headText">Know Who I am</h1>
            <p id="firstText">
              I am from the culturally rich and scenic city of Muscat, Oman. As
              a Senior Computer Science student at Dalhousie University. I am
              passionately coding my path through the complexities of
              technology.
            </p>
            <p id="secondText">
              In addition to my academic pursuits, I engage in various
              activities to maintain a balanced lifestyle!
            </p>
            <ul className="activities">
              <li className="activity-1">
                Lifting{" "}
                <span className="dumbbell">
                  <img src="/assets/icons/dumbbell.png" alt="dumbbell" />
                </span>
              </li>
              <li className="activity-2">
                Ice skating{" "}
                <span className="ice">
                  <img
                    src="/assets/icons/iceSkates.png"
                    alt="iceSkates"
                    className="ice"
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="skillsSection">
          <div className="professional-skillSet">
            <Slider
              width="200px"
              duration={30}
              pauseOnHover={true}
              blurBorders={false}
            >
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/java.png" alt="Java" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/python.png" alt="Python" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/html.png" alt="HTML" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/css.png" alt="CSS" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/javascript.png" alt="JavaScript" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/cProgramming.png" alt="C Programming" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/reactjs_icon.png" alt="React" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/nodejs.png" alt="Node.js" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/express.png" alt="Express" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/postgresql.png" alt="PostgreSQL" />
              </Slider.Slide>
            </Slider>
          </div>
          <div className="professional-skillSet">
            <Slider
              width="200px"
              duration={30}
              pauseOnHover={true}
              blurBorders={false}
            >
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/intellij_idea.png" alt="intellij" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/clion.png" alt="cLion" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/pycharm.png" alt="pycharm" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img
                  src="/assets/icons/android_studio.png"
                  alt="androidStudio"
                />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/figma.png" alt="figma" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/firebase.png" alt="firebase" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/git.png" alt="gitPic" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/postman.png" alt="postman" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/vscode.png" alt="vscode" />
              </Slider.Slide>
              <Slider.Slide className="sliderStyle">
                <img src="/assets/icons/wireshark.png" alt="wireshark" />
              </Slider.Slide>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AboutPage;
