import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../styles/blogPage.css";
import MonitorScene from "../components/MonitorScene";

function BlogPage() {
  const blogs = [
    {
      id: 1,
      name: "LA CTF 2025",
      challenge: "Arclbroth (Web Exploitation)",
      description:
        "Bypassed registration restrictions using a null byte injection to register as an existing admin user.",
      image: "/assets/images/lactf.jpg",
      readBlog: "/blogs/arclbroth/",
    },
    {
      id: 2,
      name: "TUCTF 2024",
      challenge: "Silly Cloud (Web Exploitation)",
      description:
        "Exploited a Kubernetes misconfiguration using LFI to obtain credentials and access secrets.",
      image: "/assets/images/TUCTF.jpg",
      readBlog: "/blogs/sillyCloud/",
    },
    {
      id: 3,
      name: "LA CTF 2025",
      challenge: "MavsFan (Web Exploitation)",
      description:
        "Exploited a stored XSS using an SVG payload to exfiltrate the admin cookie.",
      image: "/assets/images/lactf.jpg",
      readBlog: "/blogs/mavsFan/",
    },
  ];
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
  };

  const nextSlide = () => {
    if (isAnimating) return;
    setAnimationClass("fade-out");
    setIsAnimating(true);
    setTimeout(() => {
      const newIndex = (currentIndex + 1) % blogs.length;
      preloadImage(blogs[newIndex].image);
      setCurrentIndex(newIndex);
      setAnimationClass("fade-in");
      setTimeout(() => setIsAnimating(false), 600);
    }, 500);
  };

  const prevSlide = () => {
    if (isAnimating) return;
    setAnimationClass("fade-out");
    setIsAnimating(true);
    setTimeout(() => {
      const newIndex = (currentIndex - 1 + blogs.length) % blogs.length;
      preloadImage(blogs[newIndex].image);
      setCurrentIndex(newIndex);
      setAnimationClass("fade-in");
      setTimeout(() => setIsAnimating(false), 600);
    }, 500);
  };

  return (
    <div className="page-transition">
       <Helmet>
        <title>Ahmed Al-Naamani | Blogs</title>
        <meta
          name="description"
          content="Explore Ahmed Al-Naamani's blog posts, including detailed CTF write-ups."
        />
        <meta property="og:title" content="Ahmed Al-Naamani | Blogs" />
        <meta
          property="og:description"
          content="Check out the latest blog posts from Ahmed Al-Naamani, covering CTF challenges and more."
        />
        <meta
          property="og:image"
          content="https://ahmedalnaamani.dev/assets/images/blogs.webp"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content="https://ahmedalnaamani.dev/blogs" />
        <meta property="og:type" content="website" />
        <link
          rel="canonical"
          href="https://ahmedalnaamani.dev/blogs"
        />
      </Helmet>
      <div className="carouselContainer">
        <h1 className="blogTitle">CTF Writeups</h1>
        <p className="blogSubtitle">Explore my latest CTF challenge solutions</p>
        <div className="carousel">
          <div className={`carouselCard ${animationClass}`}>
            <div className="monitorWrapper">
              <MonitorScene image={blogs[currentIndex].image} />
            </div>
            <h2 className="blogName">{blogs[currentIndex].name}</h2>
            <p className="challengeName">{blogs[currentIndex].challenge}</p>
            <p className="blogDescription">
              {blogs[currentIndex].description}
            </p>
            <div className="blogButton">
              {blogs[currentIndex].readBlog && (
                <button
                  className="readBlogButton"
                  onClick={() =>
                    navigate(blogs[currentIndex].readBlog)
                  }
                >
                  Read
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="arrow left" onClick={prevSlide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="white"
            >
              <path
                d="M15.5 19.5L7.5 12L15.5 4.5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="arrow right" onClick={nextSlide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="white"
            >
              <path
                d="M8.5 4.5L16.5 12L8.5 19.5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPage;
