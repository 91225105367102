import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import "../styles/contact.css";
import PosingAvatar from "../components/PosingAvatar";
import { Helmet } from "react-helmet-async";

function ContactPage() {
  const [state, handleSubmit] = useForm(process.env.REACT_APP_FORMSPREE_API);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    await handleSubmit(event);

    setIsLoading(false);

    event.target.reset();

    setShowSuccess(true);

    setTimeout(() => {
      setShowSuccess(false);
    }, 1500);
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  return (
    <div className="contact-container">
      <Helmet>
        <title>Ahmed Al-Naamani | Contact</title>
        <meta
          name="description"
          content="Get in touch with Ahmed Al-Naamani. Reach out for collaborations, inquiries, or just to say hello."
        />
        <meta property="og:title" content="Ahmed Al-Naamani | Contact" />
        <meta
          property="og:description"
          content="Use the contact form to send a message directly to Ahmed Al-Naamani."
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image" content="https://ahmedalnaamani.dev/assets/images/blogs.webp" />
        <meta property="og:url" content="https://ahmedalnaamani.dev/contact" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://ahmedalnaamani.dev/contact" />
      </Helmet>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-message">
            <p>
              Sending<span className="loading-dots">...</span>
            </p>
          </div>
        </div>
      )}

      {showSuccess && (
        <div className="success-overlay" onClick={handleCloseSuccess}>
          <div className="success-message" onClick={(e) => e.stopPropagation()}>
            <p>Thank you! Your message has been sent successfully.</p>
          </div>
        </div>
      )}

      <div className="contact-header-box">
        <h1>Contact Me</h1>
        <div className="avatar-overlay">
          <PosingAvatar />
        </div>
      </div>

      <div className="contact-form-box">
        <form onSubmit={onSubmit} autoComplete="off">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            required
            autoComplete="off"
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />

          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            required
            autoComplete="off"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />

          <textarea
            id="message"
            name="message"
            placeholder="Your Message"
            required
            autoComplete="off"
          ></textarea>
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />

          <button type="submit" disabled={state.submitting}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactPage;
