import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/navbar.css";

function NavBar({ onNavClick }) {
  const [active, setActive] = useState(false);
  const [navColor, setNavColor] = useState(false);
  const [closing, setClosing] = useState(false);
  const navigate = useNavigate();

  const navToggle = () => {
    if (active) {
      setClosing(true);
      setTimeout(() => {
        setActive(false);
        setClosing(false);
        document.body.classList.remove("no-scroll");
      }, 5);
    } else {
      setActive(true);
      document.body.classList.add("no-scroll");
    }
  };

  const handleNavLinkClick = () => {
    if (active) {
      setClosing(true);
      setTimeout(() => {
        setActive(false);
        setClosing(false);
        document.body.classList.remove("no-scroll");
      }, 5);
    }
  };

  useEffect(() => {
    const scrollHandler = () => {
      if (window.scrollY >= 80) {
        setNavColor(true);
      } else {
        setNavColor(false);
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const handleOnBrandClick = (e) => {
    e.preventDefault();
    navigate("/");
  };
  const isActive = (path) => {
    if (path === "/blogs") {
      return window.location.pathname.startsWith("/blogs");
    }
    return window.location.pathname === path;
  };
  return (
    <div className="navbar-container">
      <nav className={`navbar ${navColor ? "navbar-scrolled" : ""}`}>
        <Link to="/" onClick={handleOnBrandClick} className="navbar-brand">
          <span className="brand-symbol">&lt;</span>
          <span className="brand-name">Ahmed</span>
          <span className="brand-symbol">/&gt;</span>
        </Link>
        <ul
          className={`navbar-menu ${active ? "active" : ""} ${
            closing ? "closing" : ""
          }`}
        >
          <li className="navbar-item">
            <Link
              to="/"
              onClick={handleNavLinkClick}
              className={`navbar-link ${isActive("/") ? "active" : ""}`}
            >
              Home
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="/about"
              onClick={handleNavLinkClick}
              className={`navbar-link ${isActive("/about") ? "active" : ""}`}
            >
              About
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="/projects"
              onClick={handleNavLinkClick}
              className={`navbar-link ${isActive("/projects") ? "active" : ""}`}
            >
              Projects
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="/blogs"
              onClick={handleNavLinkClick}
              className={`navbar-link ${
                isActive("/blogs") ? "active" : ""
              }`}
            >
              Blogs
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="/experiences"
              onClick={handleNavLinkClick}
              className={`navbar-link ${
                isActive("/experiences") ? "active" : ""
              }`}
            >
              Experiences
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="/contact"
              onClick={handleNavLinkClick}
              className={`navbar-link ${isActive("/contact") ? "active" : ""}`}
            >
              Contact
            </Link>
          </li>
        </ul>
        <div
          onClick={navToggle}
          className={`navbar-toggle ${active ? "open" : ""}`}
        >
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
