import React from "react";
import "../styles/homePage.css";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import WavingAvatar from "../components/WavingAvatar";
import { Helmet } from "react-helmet-async";

function HomePage() {
  const [text] = useTypewriter({
    words: ["Full Stack Web Developer", "Software Engineer"],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });
  return (
    <div className="page-transition">
      <Helmet>
        <title>Ahmed Al-Naamani | Home</title>
        <meta name="description" content="Welcome to the personal portfolio of Ahmed Al-Naamani, a Full Stack Web Developer and Software Engineer." />
        <meta property="og:title" content="Ahmed Al-Naamani | Full Stack Developer & Software Engineer" />
        <meta property="og:description" content="Explore projects, blogs, and more on my personal portfolio." />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image" content="https://ahmedalnaamani.dev/assets/images/blogs.webp" />
        <meta property="og:url" content="https://ahmedalnaamani.dev" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://ahmedalnaamani.dev" />
      </Helmet>
      <div className="home-cont">
        <div className="me">
          <WavingAvatar />
        </div>
        <div className="text-cont">
          <div className="text-1">Hey There!</div>
          <div className="text-2">
            I'M <span id="name">Ahmed Al-Naamani</span>
          </div>
          <div className="text-3">
            {text}
            <span id="cursor">
              <Cursor />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HomePage;
